.dropdownLayout{
    width: 200px;
    height: 40px;
    position: relative;
    display: inline-block;
    padding-left: 50px;
}

.act_like_dropdown{
    z-index: 100;
    position: absolute;
    min-width: 100%;
    margin: 2px;
    padding:10px;
    right: 0;
}

.right_close_button{
    float: right;
    margin: 5px;
}
.MuiFormControlLabel-root{
    margin: 0 !important;
}