.App {
  text-align: center;
}
.page {
  background-color: #f9f9f9;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 32px;
}
.MuiToggleButton-root-42.Mui-selected{
  background-color: aliceblue !important;
}