.task-container{
    width: 600px;
    /* Inside Auto Layout */
    display: flex;
    justify-content: center;
    align-items: center;
}

.task-heading{
    width: fit-content;
    display: flex;
    justify-content: flex-start;
}

.sub-task-container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}