.total-account-count-container {
    padding-top: 12px;
  width: 20%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-width: 150px;
}
