.task-bar{
    width: 542px;
    height: 48px;
    left: 0px;
    top: 48px;

    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 4px;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 0px;
}

.task-bar-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    margin: 0 16px 0px 16px;
}

.alert-text{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    letter-spacing: 0.15px;

    /* text / primary */

    color: rgba(0, 0, 0, 0.87);
}


.alert-count-container{
    width: fit-content;
    height: 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: right;

    color: #000000;
}

.alert-count{
    display: flex;
    align-items: center;
    gap: 12px;
    width: fit-content;
    justify-content: flex-end;

}
.task-heading{
    align-items: center;
}

a {
    text-decoration: none;
    color:rgba(0, 0, 0, 0.87);
}